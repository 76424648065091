<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";

export default {
  name: "AllPlanFees",
  components: { DataTableComponent },
  data() {
    return {
      table: {
        modal: "Fee Plan",
        path: "feeplans",
        headers: [
          // {
          //   text: "Plan ID",
          //   value: "id"
          // },
          {
            text: this.$i18n.t("Name"),
            value: "en.title",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.title",
          },
          {
            text: this.$i18n.t("Notes"),
            value: "en.notes", // not sortable item
            sortable: false,
          },

          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "3%",
          },
        ],
        title: this.$i18n.t("Fee Plans"),
        apiEndPoints: {
          list: "fees",
          create: "fees/store",
          edit: "fees/update",
          delete: "fees/destroy",
        },
        editedItem: {
          id: "",
          en: { title: "", notes: "" },
          ar: { title: "", notes: "" },
        },
        permissions: {
          list: "list-fee-plans",
          add: "add-fee-plans",
          edit: "edit-fee-plans",
          delete: "delete-fee-plans",
          print: "print-fee-plans",
          show: "list-master-fee-plans",
        },
      },
    };
  },
};
</script>
